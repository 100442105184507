main{
    background-color: white;
    border-radius: 10px;
}
h1{
    justify-self: center;
    height: fit-content;

}
.cascaderContainer{
    display: grid;
    padding: 10px;
}
.cascaderNavbar{
    padding: 10px;
}
.btnsContainer{
    display: grid;
    justify-self: center;
    gap: 10px;
    grid-template-columns: repeat(2, 300px);
    width: 80%;
    padding: 10px;
}
.btnUserManagement{
    justify-self: center;
    width: 78%;
}