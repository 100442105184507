.navBarContainer{
    background-color: rgba(251, 254, 202, 0.91);
    border-radius: 10px;
    height: fit-content;
    justify-items: center;
    grid-area: navbar;
    display: grid;
    grid-auto-flow: column;

}
.navbar{
color: black;
    text-decoration: none;
}
.active{
    color: red;
}
.active:hover{
    color: #ff9d00;
}
.navbar:hover{
    color: #ff9d00;
}