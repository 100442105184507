.container{
    display: grid;
   background-color: rgba(251, 254, 202, 0.91);
    border-radius:0 0 10px 10px;
    width: 100%;
    min-height: 100vh;
    padding:1% 5% 5%;
    font-size: 1.5em;

}
.containerPage{
    display: grid;
    grid-area: outlet;
    justify-items: center;
}

