.container{
    display: grid;
    height: fit-content;
    background-color: rgba(251, 254, 202, 0.91);
    border-radius: 10px;
    padding: 10px;
}
.formLogIn{
    display: grid;
}
.btnsGroup{
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
}