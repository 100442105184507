.container{
    display: grid;
    grid-auto-flow: column;
justify-items: left;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: rgba(251, 254, 202, 0.91);
    grid-area: footer;
}
.dateFooter{
    display: grid;
    grid-auto-flow: column;
    width: fit-content;
}
.navBar{
    width: 100%;
   justify-self: center;

}