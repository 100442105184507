.container{
    display: grid;
    border-radius: 10px;
    background-color: rgba(251, 254, 202, 0.91);
padding-right: 5px;
}
.btnContainer{
    padding-top: 20px;
}
.btn{
    width: 100%;
}
.meta{
    display: grid;
    width: 100%;
    justify-items: center;
}