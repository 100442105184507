.container{
    display: grid;
    height: fit-content;
    background-color: rgba(251, 254, 202, 0.91);
    border-radius: 10px;
    padding-bottom: 10px;
}
ul{
    list-style: none;
}
.collapse{
    display: grid;
    grid-auto-flow: row;
}
.articlesList{
    display: grid;
    height: fit-content;
    width: fit-content;
}
.Link{
    color: black;
}
.Link:hover{
    color: #ff9d00;

}