body {
  top: 0;
  bottom: 0;
  display: grid;
  justify-items: center;
  background-image: url("./Images/background-beekeeping.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


