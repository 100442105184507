.container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 10px;
    grid-template-areas:
        'header header'
        'navbar aside'
        'outlet aside'
        'footer footer';
    width: fit-content;
    backdrop-filter: blur(5px) ;
background: rgba(13, 12, 12, 0.33);
min-height: 100vh;
    border-radius: 10px;
    padding: 10px;
}
h3{
    text-align: center;
}
aside {
    display: grid;
    gap: 10px;

    grid-area: aside;
    height: fit-content;
}

.link{
    position: fixed;
    margin-left: 85%;
    border: 2px #f7f4c5 solid;
    border-radius: 50%;
    padding: 10px;
    background: #f5ed5a;
    scroll-behavior: smooth;
    z-index: 9999;
}
@media screen and (max-width: 800px){
  .container{
      grid-template-columns: 1fr;
      grid-template-areas:
        'header '
        'navbar'
        'aside'
        'outlet'
        'footer';
  }
    .link{
        font-size: 30px;
    }
}