.containerMediaEditor{
    display: grid;
    gap:20px
}
.mediaItems{
    display: grid;
    min-height: 250px;
gap: 10px;
    grid-template-columns: repeat(2, fit-content(200px));
    overflow: auto;
}
.uploadImage{
    display: grid;
    gap: 10px;
    width: fit-content;
    grid-auto-flow: column;
}
.cardImage{
    display: grid;
    gap: 10px;
grid-auto-flow: column;
}

.containerActions{
    display: grid;
}

.pagination{
    justify-self: center;
}