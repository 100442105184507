.form{
    width: 100%;
    padding: 10px;
    display: grid;
    justify-self: center;
    gap: 10px;
}
.select, .input{
  width: 600px;
    padding-left: 20px;
}
.buttons {
    display: grid;
    grid-auto-flow: column;
    width: 40%;
    gap: 10px;
}
.btns{
    justify-self: center;
}
